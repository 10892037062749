import { h, FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import styles from "./Excerpt.module.scss";

import { Text, ITextProps } from "@components/Text";
import { useTranslation } from "react-i18next";

interface IExcerptProps extends ITextProps {
    excerpt: string;
    complete: string;
}

export const Excerpt: FunctionalComponent<IExcerptProps> = ({
    excerpt,
    complete,
    ...props
}) => {
    const { t } = useTranslation();
    const [showFull, setShowFull] = useState(false);

    const value = showFull ? complete : excerpt + "... ";

    return (
        <Text {...props}>
            {value}
            {showFull || (
                <span
                    className={styles.readMore}
                    onClick={() => setShowFull(true)}
                    data-testid="excerpt-read-more"
                >
                    {t("readMore")}
                </span>
            )}
        </Text>
    );
};