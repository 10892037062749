import { h, FunctionalComponent, JSX } from "preact";
import clsx from "clsx";
import styles from "./Card.module.scss";

export interface ICardProps {
    className?: string;
    space?: {
        top?: number;
        bottom?: number;
    };
    style?: JSX.CSSProperties;
    dataTestId?: string;
}

export const Card: FunctionalComponent<ICardProps> = ({
    className,
    style: componentStyle = {},
    space: {
        top = 0,
        bottom = 0,
    } = {},
    dataTestId,
    children,
}) => {

    const style: JSX.CSSProperties = {};
    if (top !== 0) style.paddingTop = `${top}px`;
    if (bottom !== 0) style.paddingBottom = `${bottom}px`;

    return (
        <div
            className={clsx(styles.card, className)}
            style={{
                ...style,
                ...componentStyle,
            }}
            data-testid={dataTestId}
        >{children}</div>
    );
};