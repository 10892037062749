import { createRouteMap } from "@model/routes";
import { steps } from "./config";

export const routeMap = createRouteMap({
    base: "/credit-tool",
    errorPage: "error",
    routes: {
        // "splash": { next: "step-1" },
        ...steps,
        "eid": { next: "score", back: false, prefetch: ["error"] },
        "score": { next: "application", back: false },
        "score-details": { back: true },
        "postal-score": { next: "application", back: false },
        "error": { next: "application", back: false },
        "equifax-down": { next: "application", back: false },
        "eid-failure": { next: "application", back: false },
        "fraud-check": { next: "application", back: false },
        "application": { next: "thank-you", back: "score" },
        "thank-you": {},
    },
});

export type { CreditToolRouteMapping } from "@model/routes";