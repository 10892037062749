import { createRouteMap } from "@model/routes";

export const routeMap = createRouteMap({
    base: "/id-tool",
    errorPage: "error",
    routes: {
        // "splash": { next: "step-1" },
        "step-1": {},
        "error": { next: "step-1", back: false },
        // "thank-you": {},
    },
});

export type { IdToolRouteMapping } from "@model/routes";