import { h, FunctionalComponent, VNode, JSX } from "preact";
import { useState } from "preact/hooks";
import clsx from "clsx";
import { Spinner } from "@components/Spinner";
import styles from "./Button.module.scss";

export interface IButtonProps {
    className?: string;
    onClick: (e: JSX.TargetedEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    prefixIcon?: VNode;
    suffixIcon?: VNode;
    loading?: boolean;
    dataTestId?: string;
}

export const Button: FunctionalComponent<IButtonProps> = ({
    className,
    onClick,
    disabled,
    children,
    prefixIcon,
    suffixIcon,
    loading,
    dataTestId,
}) => {
    const [clicked, setClicked] = useState(false);

    const toggleAnimation = (): void => {
        setClicked((curState) => !curState);
    };

    const handleClick: JSX.MouseEventHandler<HTMLButtonElement> = (e): void => {
        toggleAnimation();
        onClick(e);
    };

    return (
        <button
            className={clsx(styles.button, className, clicked && styles.animate)}
            onClick={handleClick}
            onAnimationEnd={toggleAnimation}
            disabled={disabled}
            data-testid={dataTestId}
        >
            <div className={styles.iconPrefix}>
                {prefixIcon}
            </div>
            <span
                style={loading ? {
                    visibility: "hidden",
                } : {}}
            >
                {children}
            </span>
            {loading && (
                <div className={styles.spinnerContainer}>
                    {/* <Spinner className={styles.loadingSpinner} /> */}
                    Loading...
                </div>
            )}
            <div className={styles.iconSuffix}>
                {suffixIcon}
            </div>
        </button>
    );
};