import type { RouteManifest } from "./manifest.auto";
import { ProductType } from "@model/als";

export type { RouteManifest };

type NoPrefix<
    TPrefix,
    TValue,
    TElse = string,
> = (
        TPrefix extends "/"
        ? TValue extends `/${infer TSuffix}`
        ? TSuffix
        : TElse
        : TValue extends `${Extract<TPrefix, string>}/${infer TSuffix}`
        ? TSuffix
        : TElse
    );

export type WithPrefix<
    TPrefix extends string,
    TValue extends string
> = {
    [K in TValue]: `${TPrefix}${TValue}`
}[TValue];

export type SubRoutesOf<
    TBase extends keyof RouteManifest = keyof RouteManifest,
> = Exclude<{
    [K in keyof RouteManifest]: NoPrefix<TBase, K, never>;
}[keyof RouteManifest], never>

export type RoutesFor<T extends keyof RouteManifest> = keyof RouteManifest[T];

export type RouteMapping<
    TBase extends keyof RouteManifest = keyof RouteManifest,
    TInclude extends RoutesFor<TBase> = RoutesFor<TBase>,
> = {
    base: TBase;
    start: SubRoutesOf<TBase>;
    errorPage?: SubRoutesOf<TBase>;
    routes: {
        [K in NoPrefix<TBase, Extract<RoutesFor<TBase>, TInclude>>]?: {
            target?: string;
            next?: Exclude<SubRoutesOf<TBase>, K>;
            back?: Exclude<SubRoutesOf<TBase>, K> | boolean;
            prefetch?: Exclude<SubRoutesOf<TBase>, K>[];
        }
    }
    product?: (typeof routeProductMap)[Extract<TBase, keyof typeof routeProductMap>];
}

export type AnyRouteMapping = RouteMapping<keyof RouteManifest>;

type RouteToProductMap = {
    [K in keyof RouteManifest]?: ProductType;
};

const createRouteToProductMap = <T extends RouteToProductMap>(mapping: T): T => mapping;
export const routeProductMap = createRouteToProductMap({
    "/credit-tool": ProductType.CreditTool,
    "/credit-lite-tool": ProductType.CreditLiteTool,
    "/trade-tool": ProductType.TradeTool,
    "/drive-tool": ProductType.DriveTool,
    "/id-tool": ProductType.IdTool,
});

export type CreditToolRouteMapping = RouteMapping<"/credit-tool">;
export type CreditLiteToolRouteMapping = RouteMapping<"/credit-lite-tool">;
export type TradeToolRouteMapping = RouteMapping<"/trade-tool">;
export type LenderToolRouteMapping = RouteMapping<"/lender-tool">;
export type MSAToolRouteMapping = RouteMapping<"/msa-tool">;
export type DriveToolRouteMapping = RouteMapping<"/drive-tool">;
export type IdToolRouteMapping = RouteMapping<"/id-tool">;

export function createRouteMap<
    K extends keyof RouteManifest,
    T extends RouteMapping<K>["routes"]
>({
    base,
    routes,
    errorPage,
}: {
    base: K,
    routes: T
    errorPage?: SubRoutesOf<K>
}): RouteMapping<K> {
    return {
        base,
        start: Object.keys(routes)[0] as SubRoutesOf<K>,
        errorPage,
        routes,
        product: routeProductMap[base as Extract<K, keyof typeof routeProductMap>],
    };
}
