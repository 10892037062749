import { h, FunctionalComponent, Fragment } from "preact";
import { useTranslation } from "react-i18next";
import { useState } from "preact/hooks";

import { SubText } from "@components/Text/SubText";
import { Expandable } from "@components/Expandable";
import { Text } from "@components/Text";

import equifaxLogo from "@assets/equifax-logo-189x36.png";
import { IUserState, useRootModels } from "@store";
import { CountryEnum } from "@shared/graphql/schema";


import { Footer } from "../Footer";
import styles from "../Footer.module.scss";

// Add test profiles data
const testAddress = {
    streetNumber: "2904",
    street: "Carambola Cir S",
    address: "2904 Carambola Cir S",
    unit: "",
    postalCode: "33066",
    city: "Coconut Creek",
    province: "FL",
    country: "USA",
}
type TestProfile = Partial<IUserState> & { display: string }
const TEST_PROFILES: TestProfile[] = [
    { display: "Pass", firstName: "Abby", lastName: "CaineenUAT", ...testAddress },
    { display: "Fail", firstName: "Martin", lastName: "SmithUAT", ...testAddress },
    { display: "Review", firstName: "Quinn", lastName: "RosasUAT", ...testAddress },
];

interface IDefaultFooterProps {
    showLogo?: boolean;
    learnMore?: boolean;
    showTestProfile?: boolean
    country?: string;
}

export const DefaultFooter: FunctionalComponent<IDefaultFooterProps> = ({
    showLogo = true,
    learnMore = true,
    showTestProfile,
    country = "CA",
    children,
}) => {
    const { t } = useTranslation();
    const [selectedProfile, setSelectedProfile] = useState<TestProfile | null>(null);
    const {
        user: {
            merge,
        },
        dealer: {
            state: dealerState,
        },
    } = useRootModels();
    const privacyPolicyUrl = dealerState.widget?.group.privacyPolicyUrl;

    return (
        <Fragment>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {showLogo && (
                    <div>
                        <Text style={{ fontSize: "12px", marginRight: "12px" }}>
                            {t(`credit.footer.${country}.poweredBy`)}
                        </Text>
                        <img src={equifaxLogo} width={110} style={{ marginBottom: "-5px" }} />
                    </div>
                )}

                {showTestProfile && TEST && dealerState.country === CountryEnum.Us && <div>
                    <Text
                        style={{
                            fontSize: "12px",
                            marginBottom: "2px",
                            display: "block",
                            fontWeight: "bold"
                        }}
                    >
                        Test Profile (USA)
                    </Text>
                    <select
                        placeholder="Test Profile"
                        value={selectedProfile ? `${selectedProfile.firstName}-${selectedProfile.lastName}` : ""}
                        onChange={(e) => {
                            const [firstName, lastName] = (e.target as HTMLSelectElement).value.split("-");
                            const newProfile = TEST_PROFILES.find(
                                (profile) => profile.firstName === firstName && profile.lastName === lastName,
                            );
                            if (newProfile) {
                                setSelectedProfile(newProfile);
                                merge({
                                    ...newProfile
                                });
                            }
                        }}
                        style={{
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            width: "100%"
                        }}
                        data-testid="test-profile-select"
                    >
                        <option value="">Select a profile</option>
                        {TEST_PROFILES.map((profile) => (
                            <option
                                key={`${profile.firstName}-${profile.lastName}`}
                                value={`${profile.firstName}-${profile.lastName}`}
                            >
                                {`${profile.display} - ${profile.firstName} ${profile.lastName}`}
                            </option>
                        ))}
                    </select>
                </div>}
            </div>
            <Footer>
                {privacyPolicyUrl && (
                    <div>
                        <a href={privacyPolicyUrl} target="_blank" rel="noreferrer" className={styles.link}>
                            {t(`privacyPolicy`)}
                        </a>
                    </div>
                )}
                {learnMore && (
                    <Expandable
                        title={t(`credit.footer.${country}.learnMore.title`)}
                        expanded
                        content={(
                            <SubText block dataTestId="learn-more-content">
                                {t(`credit.footer.${country}.learnMore.content`)}
                            </SubText>
                        )}
                    />
                )}
                {children}
            </Footer>
        </Fragment>
    );
};