import { h, FunctionalComponent } from "preact";
import { useTranslation } from "react-i18next";
import { useCreditToolModels } from "@credit-tool/state";
import { Text } from "@components/Text/Text";
import { useProductRouter } from "@hooks/use-product-router";

import styles from "./Header.module.scss";
import CloseIcon from "@assets/close-icon.svg";
import BackIcon from "@assets/back-icon.svg";
import { CountryEnum } from "@shared/graphql/schema";
import cbbLogo from "@assets/cbb-logo.png";
export interface IHeaderProps {
    backButton: boolean;
    closeButton: boolean;
    goToPrev: () => void;
    onClose?: () => void;
    // progress?: number;
}

const LeftPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelLeft}>{children}</div>
);
const RightPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelRight}>{children}</div>
);

export const TradeToolHeader = () => {
    return (
        <div className={styles.header}>
            <div className={styles.cbb}>
                <img src={cbbLogo} alt="trade-tool-header-logo" />
            </div>
        </div>
    );
};


export const Header: FunctionalComponent<IHeaderProps> = ({
    children,
    backButton,
    closeButton,
    onClose,
    goToPrev,
}) => {
    const { t, i18n } = useTranslation();
    const {
        products: { state: productsState },
        user: { update: updateUser },
        dealer: { state: dealerState },
    } = useCreditToolModels();
    const router = useProductRouter();
    const hasTranslations = (
        productsState.creditLiteTool ||
        productsState.creditTool ||
        productsState.driveTool ||
        productsState.msaTool ||
        productsState.tradeTool
    );
    const country = dealerState.country;
    // We want to avoid additional API hits to the Backend,
    // so we will hide the language dropdown on the following pages:
    //  - "/confirm"
    //  - "/eid"
    //  - "/score"
    //  - "/score-details"
    const showLanguageDropdown = (
        !router.curRoute.includes("confirm") &&
        !router.curRoute.includes("eid") &&
        !router.curRoute.includes("score") &&
        !router.curRoute.includes("score-details")
    );

    // Hide close button in score-details page due to unable to load credit inquiries
    // or trade lines error
    const showCloseButton = (
        !router.curRoute.includes("score-details")
    );

    return (<>
        <div className={styles.header}>
            <LeftPanel>
                {backButton && (
                    <div className={styles.backIconContainer} onClick={goToPrev}>
                        <BackIcon />
                        <Text block className={styles.backIconText}>{t("back")}</Text>
                    </div>
                )}
            </LeftPanel>
            <RightPanel>

                {hasTranslations && showLanguageDropdown && (
                    <select
                        value={i18n.language}
                        onChange={(e) => {
                            updateUser.language(e.currentTarget.value);
                            i18n.changeLanguage(e.currentTarget.value);
                        }}
                        className={styles.langDropdown}
                        style={{
                            cursor: "pointer",
                            marginRight: closeButton ? "16px" : "0",
                            marginTop: productsState.tradeTool ? "-77px" : "0",
                            zIndex: 1000,
                        }}
                        data-testid="language-select"
                    >
                        {country === CountryEnum.Ca && <option value="en-CA">🇨🇦 - English</option>}
                        {country === CountryEnum.Ca && <option value="fr-CA">🇨🇦 - Français</option>}

                        {country === CountryEnum.Us && <option value="en-US">🇺🇸 - English</option>}
                        {country === CountryEnum.Us && <option value="es-US">🇺🇸 - Español</option>}
                    </select>
                )}

                {closeButton && showCloseButton && (
                    <div className={styles.closeIcon} onClick={onClose}>
                        <CloseIcon />
                    </div>
                )}
            </RightPanel>
            {children}
        </div>
    </>
    );
};