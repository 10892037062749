
const DEFAULT_STEPS = [
    "step-1",
    "step-2",
    "phone-verify",
    "step-3",
    "ssn", // USA only
    "confirm",
    "eid",
].filter(Boolean);

// HELPER TO GENERATE MANY STEPS
export const order = DEFAULT_STEPS;

export const MAX_QUESTIONS = 3;

export const getStepNumber = (search: string) => {
    return order.findIndex((v) => search === v) + 1;
};

export const steps = order.reduce((agg, step, i) => {
    if (step === "phone-verify") {
        return ({
            ...agg,
            [step]: {
                next: "step-3",
                back: true,
            },
        });
    }
    if (step === "step-3") {
        return ({
            ...agg,
            [step]: {
                next: "confirm",
                back: false,
            },
        });
    }

    return ({
        ...agg,
        [step]: {
            next: order[i + 1],
            back: !!i,
        },
    });
}, {});